import Glide from '@glidejs/glide';
import { CountUp } from 'countup.js';

class Home {
	constructor() {
		this.render();
	}

	render() {
		this.renderAnswerCount();
		this.renderTestimonialCarousel();
	}

	async renderAnswerCount() {
		const el = document.getElementById('answer-count');

		let total = el.textContent.replace(/,/g, ''); // default

		let res;

		try {
			res = await fetch('https://api.surveyplanet.com/v1/answers/total');
		} catch (err) {}

		if (res && res.ok) {
			const resJson = await res.json();

			if (!resJson || !resJson.data) {
				return;
			}

			total = Number(resJson.data);
		}

		let options = {
			duration: 1,
			useEasing: true,
			smartEasingThreshold: 10000,
		};

		const countUp = new CountUp(el, total, options);

		const observer = new IntersectionObserver((entries) => {
			entries.forEach((entry) => {
				if (entry.isIntersecting) {
					countUp.start();
				}
			});
		});
		observer.observe(el);
	}

	renderTestimonialCarousel() {
		const glide = new Glide('#testimonial-carousel', {
			type: 'carousel',
			autoplay: false,
		});

		glide.mount();
	}
}

export default new Home();
